/* Base styles */
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container styles */
.container {
  background-color: #30403B;
}

.rsvp-container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 2px solid #c41e3a; /* Holiday red */
}

/* Image styles */
.event-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
  border-radius: 8px;
}

/* Form styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  background-color: #fff;
}

/* Input styles */
input[type="text"], 
input[type="radio"],
input[type="number"],
textarea {
  width: 100%;
  height: 45px;
  padding: 12px;
  margin-bottom: 10px;
  border: 2px solid #1a472a;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
  border-color: #c41e3a;
  box-shadow: 0 0 5px rgba(196, 30, 58, 0.5);
  outline: none;
}
input[type="text"]:focus
{
  color:#fff;
}
input[type="radio"] {
  width: auto;
  height: auto;
  margin-right: 10px;
}

/* Label styles */
label {
  color: #1a472a;
  margin-right: 20px;
  font-weight: bold;
  font-size: 1.1em;
}

/* Button styles */
.btn {
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #c41e3a;
  color: white;
}

.btn-primary:hover {
  background-color: #a01830;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: #1a472a;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .2);
}

.btn-secondary:hover {
  background-color: #2a5a3a;
  transform: translateY(-2px);
}

.btn-danger {
  background-color: #8b0000;
  color: white;
}

.btn-danger:hover {
  background-color: #a11308;
}

.btn-warning {
  background-color: #d4af37;
  color: white;
}

.btn-warning:hover {
  background-color: #c4a030;
}

/* Guest input styles */
.guest-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.guest-list-heading {
  color: #1a472a;
  border-bottom: 2px solid #c41e3a;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.guest-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  border-left: 3px solid #c41e3a;
}

.guest-name {
  flex-grow: 1;
  margin-right: 10px;
  color: #1a472a;
  font-weight: 500;
}

/* Submit button styles */
.submit-rsvp-button {
  background: #c41e3a !important;
  color: white !important;
  padding: 12px 24px !important;
  border: none !important;
  border-radius: 30px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  transition: all 0.3s ease !important;
  width: 100% !important;
  font-family: 'Open Sans', sans-serif !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) !important;
}

.submit-rsvp-button:hover {
  background: #a01830 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-rsvp-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .rsvp-container {
    padding: 15px;
    margin: 10px;
  }

  .add-guest-btn::after {
    content: "+🎄";
  }

  .submit-rsvp-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* Add this to your existing styles */
textarea.form-control {
  background-color: #fff !important;
  border: 2px solid #1a472a;
  border-radius: 5px;
}

textarea.form-control:focus {
  border-color: #c41e3a;
  box-shadow: 0 0 5px rgba(196, 30, 58, 0.5);
  outline: none;
}