.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.countContainer{
  background-color: #FFFFFF;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 375px) {

  input[type=radio] {
    border: 0px;
    width: 21px;
    height: 4.5em;
  }

  input[type=text] {
    border: 0px;
    width: 150px;
    height: 1.5em;
  }

  .containerCustom {
    position: relative;
    width: 90vh;
    /* You can adjust the width as needed */
    height: 100%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }

  .fixed-textbox {
    position: absolute;
    top: 84%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 225px;
    ;
  }

  .container {
    /* height: 100vh; */
    margin: 0px auto;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 84vw;
    height: 122vw;

  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 83vw;
    height: 120vw;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 69%;
    left: 10%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 52%;
    left: 63%;
    width: 5%;

  }

  .texBoxCustom {
    position: relative;
    top: 63%;
    left: 41%;
    width: 184px;
  }



  .mealSelection1 {
    position: absolute;
    top: 26%;
    left: 10%;
  }

  .mealSelection2 {
    position: absolute;
    top: 41%;
    left: 10%;
  }

  .texBoxAllergiesCustom {
    position: absolute;
    top: 63%;
    left: 28%;
    width: 271px;
  }

  .submitRSVP {
    position: absolute;
    top: 86%;
    left: 33%;
    /* width: 220px; */
  }

}

/* Styles for screens with a maximum width of 450 pixels */
@media only screen and (min-width: 376px) and (max-width: 480px) {


  input[type=radio] {
    border: 0px;
    width: 21px;
    height: 4.5em;
  }

  input[type=text] {
    border: 0px;
    width: 150px;
    height: 1.5em;
  }

  .containerCustom {
    position: relative;
    width: 90vh;
    /* You can adjust the width as needed */
    height: 100%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }



  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 84vw;
    height: 122vw;

  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 83vw;
    height: 120vw;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 69%;
    left: 10%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 54%;
    left: 63%;
    width: 5%;

  }

  .fixed-textbox {
    position: absolute;
    top: 83%;
    left: 52%;
    transform: translate(-50%, -50%);
    width: 220px;
    ;
  }

  .texBoxCustom {
    position: relative;
    top: 62%;
    left: 41%;
    width: 100%;
  }



  .mealSelection1 {
    position: absolute;
    top: 26%;
    left: 10%;
  }

  .mealSelection2 {
    position: absolute;
    top: 41%;
    left: 10%;
  }

  .texBoxAllergiesCustom {
    position: absolute;
    top: 63%;
    left: 28%;
    width: 271px;
  }

  .submitRSVP {
    position: absolute;
    top: 86%;
    left: 33%;
    /* width: 220px; */
  }
}


/* Styles for screens with a maximum width of 600 pixels */
@media only screen and (min-width: 481px) and (max-width: 600px) {


  input[type=radio] {
    border: 0px;
    width: 21px;
    height: 4.5em;
  }

  input[type=text] {
    border: 0px;
    width: 150px;
    height: 1.5em;
  }

  .containerCustom {
    position: relative;
    width: 90vh;
    /* You can adjust the width as needed */
    height: 100%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }

  .fixed-textbox {
    position: absolute;
    top: 84%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 225px;
    ;
  }

  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 84vw;
    height: 122vw;

  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 83vw;
    height: 120vw;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 69%;
    left: 10%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 52%;
    left: 63%;
    width: 5%;

  }

  .texBoxCustom {
    position: relative;
    top: 63%;
    left: 41%;
    width: 184px;
  }



  .mealSelection1 {
    position: absolute;
    top: 26%;
    left: 10%;
  }

  .mealSelection2 {
    position: absolute;
    top: 41%;
    left: 10%;
  }

  .texBoxAllergiesCustom {
    position: absolute;
    top: 63%;
    left: 28%;
    width: 271px;
  }

  .submitRSVP {
    position: absolute;
    top: 86%;
    left: 33%;
    /* width: 220px; */
  }
}


/* Styles for screens with a minimum width of 601 pixels and a maximum of 906 pixels */
@media only screen and (min-width: 601px) and (max-width: 772px) {
  .submitRSVP {
    position: absolute;
    top: 86%;
    left: 33%;
    /* width: 220px; */
  }

  input[type=radio] {
    border: 0px;
    width: 30px;
    height: 4.5em;
  }

  .containerCustom {
    position: relative;
    width: 90vh;
    /* You can adjust the width as needed */
    height: 100%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }

  .fixed-textbox {
    position: absolute;
    top: 94%;
    left: 58%;
    transform: translate(-50%, -50%);
    width: 225px;
  }

  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    height: 122vw;

  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 94vw;
    height: 120vw;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 80%;
    left: 6%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 68%;
    left: 41%;
    width: 5%;

  }

  .texBoxAllergiesCustom {
    position: relative;
    top: 425px;
    left: 124px;
    width: 271px;
  }

  .mealSelection1 {
    width: 70px;
    position: relative;
    top: 275px;
    left: 40px;
  }

  .mealSelection2 {
    width: 70px;
    position: relative;
    top: 345px;
    left: 40px;
  }

}

/* Styles for screens with a minimum width of 601 pixels and a maximum of 906 pixels */
@media only screen and (min-width: 773px) and (max-width: 1024px) {
  .submitRSVP {
    position: absolute;
    top: 46%;
    left: 33%;
    /* width: 220px; */
  }

  input[type=radio] {
    border: 0px;
    width: 30px;
    height: 4.5em;
  }

  .containerCustom {
    position: relative;
    width: 90vh;
    /* You can adjust the width as needed */
    height: 100%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }

  .fixed-textbox {
    position: absolute;
    top: 42%;
    left: 74%;
    transform: translate(-50%, -50%);
    width: 225px;
  }

  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 126vw;

  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vw;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 36%;
    left: 8%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 30%;
    left: 62%;
    width: 5%;

  }

  .texBoxAllergiesCustom {
    position: absolute;
    top: 33%;
    left: 22%;
    width: 271px;
  }

  .mealSelection1 {
    width: 70px;
    position: relative;
    top: 14%;
    left: 6%;
  }

  .mealSelection2 {
    width: 70px;
    position: relative;
    top: 17%;
    left: 6%;
  }

}


/* Styles for screens with a minimum width of 1025 pixels */
@media only screen and (min-width: 1025px) {
  input[type=text].texBoxCustom {
    border: 0px;
    width: 185px;
    height: 2.0em;
  }

 

  input[type=radio] {
    border: 0px;
    width: 30px;
    height: 4.5em;
  }

  .containerCustom {
    position: relative;
    width: 85vh;
    /* You can adjust the width as needed */
    height: 90%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }
 
  .fixed-textbox {
    position: absolute;
    top: 66%;
    left: 69%;
    transform: translate(-50%, -50%);
    width: 185px;
  }

  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 806px;
  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 806px;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 56%;
    left: 8%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 47%;
    left: 62%;
    width: 5%;

  }

 

  .mealSelection1 {
    width: 70px;
    position: relative;
    top: 23%;
    left: 6%;
  }

  .mealSelection2 {
    width: 70px;
    position: relative;
    top: 25%;
    left: 6%;
  }

  .texBoxAllergiesCustom {
    position: absolute;
    top: 50%;
    left: 31%;
    /* width: 271px; */
  }

  .submitRSVP {
    position: absolute;
    top: 70%;
    left: 35%;
    /* width: 220px; */
  }

}



/* Styles for screens with a minimum width of 1025 pixels */
@media only screen and (min-width: 1365px) {
  input[type=text].texBoxCustom {
    border: 0px;
    width: 100%;
    height: 2.0em;
  }

 

  input[type=radio] {
    border: 0px;
    width: 30px;
    height: 4.5em;
  }

  .containerCustom {
    position: relative;
    width: 85vh;
    /* You can adjust the width as needed */
    height: 90%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }
 
  .fixed-textbox {
    position: absolute;
    top: 94%;
    left: 69%;
    transform: translate(-50%, -50%);
    width: 225px;
  }

  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 806px;
  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 806px;
    /* margin-left: -28px; */
  }

  .attendingYes {
    position: relative;
    top: 81%;
    left: 8%;
    width: 5%;

  }

  .attendingNo {
    position: relative;
    top: 71%;
    left: 62%;
    width: 5%;

  }

 

  .mealSelection1 {
    width: 70px;
    position: relative;
    top: 33%;
    left: 6%;
  }

  .mealSelection2 {
    width: 70px;
    position: relative;
    top: 40%;
    left: 6%;
  }

  .texBoxAllergiesCustom {
    position: absolute;
    top: 71%;
    left: 35%;
    /* width: 271px; */
  }

  .submitRSVP {
    position: absolute;
    top: 94%;
    left: 38%;
    /* width: 220px; */
  }

}

@media only screen 
and (device-width: 932px) 
and (device-height: 430px) 
and (orientation: landscape) { 


  .containerCustom {
    position: relative;
    width: 90vh;
    /* You can adjust the width as needed */
    height: 100%;
    /* You can adjust the height as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visibility */
  }


  .container {
    /* height: 100vh; */
    margin: 0px auto;
    background-color: #27271F;
    width: 100%;
    padding:10px 0px;
  }

  .leftColBgrd {
    background: url("./images/holiday2023_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 126vw;

  }

  .rightColBgrd {
    background: url("./images/holiday2023_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vw;
    /* margin-left: -28px; */
  }

  input[type=radio] {
    border: 0px;
    width: 20px;
    height: 3.5em;
  }

  .attendingYes {
    position: relative;
    top: 28%;
    left: 8%;
    width: 4%;

  }

  .attendingNo {
    position: relative;
    top: 22.8%;
    left: 62%;
    width: 4%;

  }

  
  .fixed-textbox {
    position: absolute;
    top: 33%;
    left: 69%;
    transform: translate(-50%, -50%);
    width: 135px;
  }

  .texBoxCustom {
    
    width: 100%;
    height:25px;
  }

  .mealSelection1 {
    width: 70px;
    position: relative;
    top: 12%;
    left: 6%;
  }

  .mealSelection2 {
    width: 70px;
    position: relative;
    top: 13%;
    left: 6%;
  }

  .allergies{
    position: absolute;
    top: 27%;
    left: 11%;
    width: 180px;
  }
  .texBoxAllergiesCustom {
       width: 100%;
    height:25px;
  }


  .submitRSVP {
    position: absolute;
    top: 38%;
    left: 33%;
    /* width: 220px; */
  }


}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


h1 {
  display: flex;
  flex-direction: row;
  width: 60%;
  text-align: center;
  margin: auto;
}

h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

h1:before {
  margin-right: 20px
}

h1:after {
  margin-left: 20px
}

p {
  margin: 0px;
}


.centerDiv {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
  vertical-align: middle;
  top: 150px;
  background-color: beige;

}

/* Remove spinner buttons for number inputs */
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}